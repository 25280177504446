<template>
  <ul class="products">
    <li v-for="product in products" :key="product.id" :class="'product product-' + product.id">
      <router-link class="router-link" :to="'/product/' + product.id">
        <div class="product-image">
          <img v-if="product.id_default_image" :src="imageUrl + '/img/p/' + product.id_default_image.split('').join('/') + '/' + product.id_default_image + '.jpg'" :alt="product.name"/>
          <img v-else src="https://via.placeholder.com/720" alt="default image">
        </div>
        <div class="product-info">
          <h3 class="product-title">{{ product.name }}</h3>
          <p class="product-price">{{ Math.round(((Math.round(product.price * 100) / 100) + ((Math.round(product.price * 100) / 100) * 0.2)) * 100) / 100 }}0€</p>
        </div>
      </router-link>
    </li>
  </ul>
</template>

<script>
export default {
  name: "ProductsComponent",
  props: {
    products: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      imageUrl: process.env.VUE_APP_API_BASE_URL
    }
  }
}
</script>

<style lang="scss" scoped>
.products {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 32px;
  padding: 32px;
  border-radius: 8px;
  background: #fdfdfd;
}
@media screen and (max-width: 1024px) {
  .products {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media screen and (max-width: 768px) {
  .products {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (max-width: 468px) {
  .products {
    grid-template-columns: 1fr;
  }
}
.product {
  border-radius: 8px;
  box-shadow: 0 5px 15px -4px rgba(0, 0, 0, 0.1);
  border: 1px solid #ededed;
  overflow: hidden;
  background: #fff;

  a {
    display: block;
    position: relative;
    color: #222527;
    height: 100%;
    transition: var(--t-transition);

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: calc(100% + 1px);
      background: rgba(0, 0, 0, 0.3);
      opacity: 0;
      pointer-events: none;
      z-index: 2;
      transition: var(--t-transition);
    }
    &::before {
      content: 'Voir le produit';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 20%;
      transform: translateY(calc(100% + 1px));
      background: rgba(var(--c-primary-rgb), 1);
      z-index: 99;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      font-weight: bold;
      color: #fff;
    }

    &:hover, &:active, &:focus-within {
       transform: translateY(-20%);
      &::after {
        opacity: 1;
      }
     }
  }

  .product-image {
    width: 100%;
    border-radius: 16px;

    img {
      width: 100%;
      padding: 16px;
    }
  }
  .product-info {
    padding: 32px;

    h3 {
      font-size: 24px;
      font-weight: bold;
      margin-bottom: 8px;
    }
    .product-price {
      font-size: 20px;
      font-weight: bold;
      color: var(--c-primary);
    }
  }
}
</style>