<template>
  <HeaderComponent/>
  <router-view/>
</template>

<script>
  import HeaderComponent from './components/HeaderComponent.vue';
  import ProductsService from './services/ProductsService';
  import '@/assets/css/reset.min.css';

  export default {
    components: {
      HeaderComponent
    },
    async mounted() {
      let routerLink = document.querySelectorAll('.router-link');

      routerLink.forEach(item => {
        item.addEventListener('click', () => {
          document.body.scrollTop = 0;
          document.documentElement.scrollTop = 0;
        });
      });

      const LOCAL_PRODUCTS = JSON.parse(localStorage.getItem("products"));
      
      if(LOCAL_PRODUCTS !== null) {
        // ProductsService.getAllProducts().then((res) => {
        //   if(res.data.products.length !== LOCAL_PRODUCTS.length) {
        //     localStorage.setItem("products", JSON.stringify(res.data.products));
        //   }
        // })
        await ProductsService.getAllProducts()
      .then((res) => {
        const DATA = res.data.products;
        
        DATA.forEach(product => {
          const CATEGORIES = product.associations.categories;
          
          if(!this.products.includes(product)) {
            this.products = [];
            CATEGORIES.forEach(category => {
                switch (category.id) {
                  case '81':
                    this.allProducts.push(product);
                    break;

                  case '40':
                    this.allProducts.push(product);
                    break;
                }
            });
          }
        });

        localStorage.setItem('products', JSON.stringify(this.allProducts));

        const LOCAL_PRODUCTS = JSON.parse(localStorage.getItem('products'));

        for(let i = this.offset; i < this.limit; i++) {
          this.products.push(LOCAL_PRODUCTS[i]);
        }
        this.isLoading = false;
      })
      }
    }
  }
</script>

<style lang="scss">
  :root {
    --c-primary: #ff9a52;
    --c-primary-shade: #c97030;
    --c-primary-rgb: 255, 154, 82;
    --c-secondary: #222527;
    --c-secondary-shade: #121414;
    --c-secondary-rgb: 34, 37, 39;
    --c-light: #fdfdfd;

    --t-transition-duration: 0.248s;
    --t-transition-ease: ease-in;
    --t-transition: var(--t-transition-duration) var(--t-transition-ease);
  }
  body {
    font-family: "Titillium Web", sans-serif;
    font-size: 14px;
    line-height: 1.5;
    color: #222527;
    background-color: #fff;
  }
  a {
    text-decoration: none;
  }
  li {
    list-style: none;
  }
  .l-constrained {
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
    padding: 0 16px;
  }
  .page {
    padding: 96px 0;
  }
  h1 {
    font-size: 32px;
    font-weight: bold;
    line-height: 1.5;
  }
  .flex-text {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
    margin-bottom: 48px;
  }
  @media (max-width: 768px) {
    .flex-text {
      flex-direction: column;
    }
  }
  .btn {
    outline: none;
    border: none;
    box-shadow: none;
    cursor: pointer;
    display: block;
    padding: 8px 32px;
    font-size: 16px;
    font-weight: bold;
    background-color: var(--c-primary);
    color: #fff;
    border-radius: 8px;
    transition: var(--t-transition);

    &:hover {
      background-color: var(--c-primary-shade);
    }
    svg, img, i {
      vertical-align: middle;
      margin-right: 8px;
    }
  }
  .product-description {
    font-size: 16px;
    font-weight: normal;
    margin: 0 0 16px;

    img {
      width: 500px;
      height: auto;
    }
    ul li {
      list-style: disc;
      margin-left: 16px;
    }
    p {
      font-size: 16px;
      font-weight: normal;
      margin: 0;
      line-height: 2;

      strong {
        font-weight: bold !important;
      }
      b {
        font-weight: bold !important;
      }
      a {
        color: var(--c-primary) !important;
      }
    }
  }
</style>