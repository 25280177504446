<template>
  <div class="product">
    <div class="product-image">
      <img v-if="product.id_default_image" :src="imageUrl + '/img/p/' + product.id_default_image.split('').join('/') + '/' + product.id_default_image + '.jpg'" :alt="product.name"/>
      <img v-else src="https://via.placeholder.com/720" alt="default image">
    </div>
    <div class="product-info">
      <h3>{{ product.name }}</h3>
      <div v-html="this.product.description_short ? this.product.description_short : 'Aucune description'" class="product-description"></div>
      <p class="product-price">{{ Math.round((priceTTC + (priceTTC * 0.2)) * 100) / 100 }}0€</p>
    </div>
  </div>
  <div class="product-details">
    <div class="tabs-btn">
      <button :class="tab === 'description' ? 'active btn' : 'btn'"  @click="tab = 'description'">Description</button>
      <button :class="tab === 'details' ? 'active btn' : 'btn'" @click="tab = 'details'">Détails</button>
    </div>
    <div v-if="tab === 'description'" class="description">
      <h3>Description</h3>
      <div v-html="this.product.description ? this.product.description : 'Aucune description'" class="product-description"></div>
    </div>
    <div v-if="tab === 'details'" class="details">
      <h3>Détails</h3>
      <p><span>Fabricant :</span>{{ product.manufacturer_name ? product.manufacturer_name : 'Aucune informations sur le fabricant' }}</p>
      <p><span>Référence :</span>{{ product.reference ? product.reference : 'Aucune référence renseigner' }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProductComponent",
  props: {
    product: {
      type: Object,
      required: true
    },
    descriptionShort: {
      type: String,
      default: "Aucune description"
    },
    description: {
      type: String,
      default: "Aucune description"
    }
  },
  data () {
    return {
      tab: "description",
      imageUrl: process.env.VUE_APP_API_BASE_URL,
      priceTTC: 0
    }
  },
  mounted() {
    this.priceTTC = Math.round(this.product.price * 100) / 100
  }
}
</script>

<style lang="scss" scoped>
.tabs-btn {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  gap: 16px;

  button {
    flex: 1;
    transition: none;
    background-color: #222527;

    &:not(.active) {
      opacity: 0.5;
    }
  }
}
h3 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 16px;
}
.product {
  display: flex;
  gap: 32px;
  width: 100%;

  .product-image {
    width: calc(720px / 1.5);
    border: 1px solid #ededed;
    border-radius: 8px;
    padding: 32px;
    background-color: #fff;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .product-info {
    padding: 32px;
    border: 1px solid #ededed;
    border-radius: 8px;
    flex: 1;

    .product-price {
      color: var(--c-primary);
      font-weight: bold;
      font-size: 32px;
    }
  }
}
.product-description{
  font-size: 16px;
  font-weight: normal;
  margin: 0 0 16px;

  p {
    font-size: 16px;
    font-weight: normal;
    margin: 0;
    line-height: 2;

    strong {
      font-weight: normal !important;
      background-color: red !important;
    }
    b {
      text-transform: uppercase !important;
      font-weight: bold !important;
    }
    a {
      color: var(--c-primary) !important;
    }
  }
}
.product-details {
  width: 100%;
  border: 1px solid #ededed;
  border-radius: 8px;
  padding: 32px;
  background-color: #fff;
  margin-top: 32px;

  p {
    font-size: 16px;
    font-weight: normal;
    margin: 0;
    line-height: 2;

    span {
      font-weight: bold;
      margin-right: 8px;
    }
  }
}

@media screen and (max-width: 840px) {
  .product {
    flex-direction: column;

    .product-image {
      width: 100%;

      img {
        width: 400px;
        margin: 0 auto;
      }
    }
  }
}
</style>