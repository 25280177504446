<template>
  <div class="skeleton-container">
    <div class="skeleton-product">
      <Skeleton class="skeleton-image product-image"/>
      <Skeleton class="skeleton-info product-info"/>
      <Skeleton class="skeleton-details product-details"/>
    </div>
  </div>
</template>

<script>
import { Skeleton } from 'vue-loading-skeleton';
import "vue-loading-skeleton/dist/style.css";

export default {
  name: "SkeletonSingleComponent",
  components: {
    Skeleton
  }
}
</script>

<style lang="scss" scoped>
.skeleton-container {

  .skeleton-product {
    display: grid;
    grid-template-columns: 480px 1fr;
    grid-template-rows: 480px 274px;
    grid-template-areas: "image info" "details details";
    gap: 32px;

    span {
      display: block;
      border-radius: 8px;
      overflow: hidden;
      .pu-skeleton {
        overflow: hidden !important;
      }
    }
    .product-image {
      grid-area: image;
      height: 100%;
      width: 100%;
    }
    .product-info {
      grid-area: info;
      height: 100%;
      width: 100%;
    }
  }
  .product-details {
    grid-area: details;
    height: 100%;
    width: 100%;
  }
}
  @media screen and (max-width: 840px) {
    .skeleton-product {
      grid-template-columns: 1fr !important;
      grid-template-rows: 466px 302px 150px !important;
      grid-template-areas: "image" "info" "details" !important;
    }
  }
</style>