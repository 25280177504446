<template>
  <div class="search-container">
    <div class="l-constrained">
      <SearchBar :input="onInput"/>
    </div>
  </div>
  <div class="page page-home">
    <div class="l-constrained">
      <div class="flex-text">
        <h1 v-if="searchValue.length === 0" class="page-title">Tous nos produits</h1>
        <h1 v-else-if="searchValue.length < 3" class="page-title">Saisissez encore {{ 3 - searchValue.length }} {{ (3 - searchValue.length) === 1 ? 'caractère' : 'caractères' }}</h1>
        <h1 v-else class="page-title">Les résultats pour "{{ searchValue }}"</h1>
      </div>
      <p v-if="error !== ''" class="error">{{ error }}</p>
      <!-- Product list -->
      <ProductsComponent v-if="products.length !== 0 && error === ''" :products="products" />
      <!-- Products loading -->
      <SkeletonListComponent v-if="isLoading === true" />
    </div>
    <ScrollToTopComponent />
  </div>
</template>

<script>
import ProductsService from "@/services/ProductsService";
import SkeletonListComponent from '@/components/SkeletonListComponent.vue';
import SearchBar from '@/components/SearchBar.vue';
import ProductsComponent from '@/components/ProductsComponent.vue';
import ScrollToTopComponent from '@/components/ScrollToTopComponent.vue';

export default {
  name: 'HomeView',
  components: {
    SkeletonListComponent,
    SearchBar,
    ProductsComponent,
    ScrollToTopComponent
  },
  data() {
    return {
      products: [],
      allProducts: [],
      error: "",
      isLoading: false,
      searchValue: "",
      offset: 0,
      limit: 10,
      tab: 41
    };
  },
  async mounted() {
    // Get products
    let LOCAL = localStorage.getItem("products");
    this.isLoading = true;

    if(LOCAL === null) {
      await ProductsService.getAllProducts()
      .then((res) => {
        const DATA = res.data.products;
        
        DATA.forEach(product => {
          const CATEGORIES = product.associations.categories;
          
          if(!this.products.includes(product)) {
            this.products = [];
            CATEGORIES.forEach(category => {
                switch (category.id) {
                  case '81':
                    this.allProducts.push(product);
                    break;

                  case '40':
                    this.allProducts.push(product);
                    break;
                }
            });
          }
        });

        localStorage.setItem('products', JSON.stringify(this.allProducts));

        const LOCAL_PRODUCTS = JSON.parse(localStorage.getItem('products'));

        for(let i = this.offset; i < this.limit; i++) {
          this.products.push(LOCAL_PRODUCTS[i]);
        }
        this.isLoading = false;
      })
    } else {
      const LOCAL_PRODUCTS = JSON.parse(localStorage.getItem('products'));
      this.products = [];

      for(let i = this.offset; i < this.limit; i++) {
        this.products.push(LOCAL_PRODUCTS[i]);
      }
      this.isLoading = false;
    }
      

      // If the user scrolls down, load more products (infinite scroll)
      if(this.products) {
        window.addEventListener('scroll',() => {
          if(this.searchValue === '') {
            if(window.innerHeight + window.scrollY >= document.body.offsetHeight) {
              const LOCAL_PRODUCTS = JSON.parse(localStorage.getItem('products'));
              this.isLoading = true;
              this.offset += this.limit;
              let limitRebase = this.limit + this.offset;

              for(let i = this.offset; i < limitRebase; i++) {
                this.products.push(LOCAL_PRODUCTS[i]);
              }
              this.isLoading = false;
            }
            console.log(this.products);
          }
        });
      }
  },
  methods: {
    // If the user search something, we load the products with the search value
    async onInput(e) {
      const LOCAL_PRODUCTS = JSON.parse(localStorage.getItem('products'));
      let input = e.target;
      let searchContainer = input.parentElement;
      this.searchValue = input.value;
      this.error = "";
      this.products = [];
      this.isLoading = true;

      // If search value is superior to 3, get products
      if(this.searchValue.length >= 3) {
        this.products = [];
        input.setAttribute('disabled', 'true');
        searchContainer.classList.add('disabled');

        //search with value
        LOCAL_PRODUCTS.forEach(product => {
          if(product.name.toLowerCase().includes(this.searchValue.toLowerCase()) || product.description_short.toLowerCase().includes(this.searchValue.toLowerCase())) {
            this.products.push(product);
          }
        });
        this.isLoading = false;
        input.removeAttribute('disabled');
        searchContainer.classList.remove('disabled');

        if(this.products.length === 0) {
          this.error = "Aucun résultat pour votre recherche";
        }

        input.focus();

      } else {
        this.offset = 0;

        for(let i = this.offset; i < this.limit; i++) {
          this.products.push(LOCAL_PRODUCTS[i]);
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.search-container {
  position: sticky;
  top: 0;
  z-index: 15;
  padding: 32px 0;
  background-color: #222527;
}
.error {
  text-align: center;
  font-size: 16px;
  padding: 8px 16px;
  background: #ffeaea;
  border-radius: 4px;
}
</style>
