<template>
  <div class="to-top-container" aria-hidden="true" title="Revenir en haut de la page">
    <button @click="scrollToTop" class="to-top">
      <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
      >
        <path
            d="M17.6568 8.96219L16.2393 10.3731L12.9843 7.10285L12.9706 20.7079L10.9706 20.7059L10.9843 7.13806L7.75404 10.3532L6.34314 8.93572L12.0132 3.29211L17.6568 8.96219Z"
            fill="currentColor"
        />
      </svg>
    </button>
  </div>
</template>

<script>
export default {
  name: "ScrollToTopComponent",
  mounted() {
    // If the page is down from 768px from the top of the document, show the button
    if(window.scrollY < 768) {
      document.querySelector('.to-top-container').setAttribute('aria-hidden', 'true');
    } else {
      document.querySelector('.to-top-container').setAttribute('aria-hidden', 'false');
    }
    // If the user scrolls down 768px from the top of the document, show the button
    window.addEventListener('scroll', () => {
      if(document.querySelector('.to-top-container')) {
        if(window.scrollY < 768) {
          document.querySelector('.to-top-container').setAttribute('aria-hidden', 'true');
        } else {
          document.querySelector('.to-top-container').setAttribute('aria-hidden', 'false');
        }
      }
    });
  },
  methods: {
    scrollToTop(e) {
      e.preventDefault();
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.to-top-container {
  position: fixed;
  bottom: 16px;
  right: 16px;
  z-index: 15;
  transition: var(--t-transition);

  &[aria-hidden="true"] {
    bottom: -50px;
    transition: var(--t-transition);
  }

  .to-top {
    position: relative;
    border: none;
    outline: none;
    box-shadow: none;
    background-color: #222527;
    color: #fff;
    border-radius: 50%;
    cursor: pointer;
    width: 48px;
    height: 48px;
    transition: var(--t-transition);

    &:hover {
      background-color: #000;
    }

    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
</style>