<template>
  <div class="nav">
    <ul>
      <li><router-link title="10ml" to="/category/41">10ml</router-link></li>
      <li><router-link title="50ml et +" to="/category/92">50ml et +</router-link></li>
      <li><router-link title="DIY (Le fait maison)" to="/category/81">DIY</router-link></li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "NavbarComponent",
}
</script>

<style lang="scss" scoped>
  .nav {

    ul {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 32px;

      li {
        flex: 1;

        a {
          display: block;
          width: 100%;
          text-align: center;
          text-decoration: none;
          padding: 8px 16px;
          border-radius: 4px;
          background-color: var(--c-primary);
          color: #fff;
          transition: var(--t-transition);

          &:hover {
            background-color: var(--c-primary-shade);
          }
        }
      }
    }
  }
</style>
