<template>
  <div class="page page-product">
    <div class="l-constrained">
        <a @click="$router.back" class="router-link back btn">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
                d="M20.3284 11.0001V13.0001L7.50011 13.0001L10.7426 16.2426L9.32842 17.6568L3.67157 12L9.32842 6.34314L10.7426 7.75735L7.49988 11.0001L20.3284 11.0001Z"
                fill="currentColor"
            />
          </svg>
          Retour à la liste des produits
        </a>
      <ProductComponent v-if="isLoading === false" :product="product" :description="description" :descriptionShort="descriptionShort" />
      <SkeletonSingleComponent v-if="isLoading === true" />
    </div>
  </div>
</template>

<script>
import ProductsService from "@/services/ProductsService";
import ProductComponent from '@/components/ProductComponent';
import SkeletonSingleComponent from '@/components/SkeletonSingleComponent';

export default {
  name: 'ProductView',
  components: {
    ProductComponent,
    SkeletonSingleComponent
  },
  data() {
    return {
      product: [],
      description: '',
      descriptionShort: '',
      productId: this.$route.params.id,
      isLoading: true
    }
  },
  beforeCreate() {
    if(Number.isNaN(Number.parseInt(this.$route.params.id, 10))) {
      this.$router.push('/');
    }
  },
  async mounted() {
    await ProductsService.getSingleProduct(this.productId).then(response => {
      this.product = response.data.products[0];
      this.isLoading = false;
      this.descriptionShort = this.htmlEscape(this.product.description_short);
      this.description = this.htmlEscape(this.product.description);
    });
  },
  methods: {
    htmlEscape(str) {
      const parser = new DOMParser();
      return parser.parseFromString(str, "text/html").documentElement.innerText;
    }
  }
}
</script>

<style lang="scss" scoped>
.back {
  margin-bottom: 32px;
  width: max-content;
}
@media (max-width: 768px) {
  .back {
    margin: 0 auto 32px;
  }
}
</style>