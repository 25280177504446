<template>
  <ul class="skeleton-products products">
    <li class="product">
      <Skeleton class="skeleton product-image"/>
      <Skeleton class="skeleton product-title"/>
      <Skeleton class="skeleton product-price"/>
    </li>
    <li class="product">
      <Skeleton class="skeleton product-image"/>
      <Skeleton class="skeleton product-title"/>
      <Skeleton class="skeleton product-price"/>
    </li>
    <li class="product">
      <Skeleton class="skeleton product-image"/>
      <Skeleton class="skeleton product-title"/>
      <Skeleton class="skeleton product-price"/>
    </li>
    <li class="product">
      <Skeleton class="skeleton product-image"/>
      <Skeleton class="skeleton product-title"/>
      <Skeleton class="skeleton product-price"/>
    </li>
    <li class="product">
      <Skeleton class="skeleton product-image"/>
      <Skeleton class="skeleton product-title"/>
      <Skeleton class="skeleton product-price"/>
    </li>
    <li class="product">
      <Skeleton class="skeleton product-image"/>
      <Skeleton class="skeleton product-title"/>
      <Skeleton class="skeleton product-price"/>
    </li>
    <li class="product">
      <Skeleton class="skeleton product-image"/>
      <Skeleton class="skeleton product-title"/>
      <Skeleton class="skeleton product-price"/>
    </li>
  </ul>
</template>

<script>
import { Skeleton } from 'vue-loading-skeleton';
import "vue-loading-skeleton/dist/style.css";

export default {
  name: 'SkeletonListComponent',
  components: {
    Skeleton
  }
}
</script>

<style lang="scss" scoped>
.skeleton-products {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 32px;
  padding: 32px;
  border-radius: 8px;
  background: #fdfdfd;

  .product {
    .skeleton {
      width: 100%;
      height: 30px;
    }
    .product-image {
      width: 326px;
      height: 326px;
    }
  }
}
@media screen and (max-width: 1024px) {
  .skeleton-products {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media screen and (max-width: 768px) {
  .skeleton-products {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (max-width: 468px) {
  .skeleton-products {
    grid-template-columns: 1fr;
  }
}
</style>