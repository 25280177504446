import axios from "axios";

class ProductsService {

    getAllProducts() {
        return axios.get(process.env.VUE_APP_API_BASE_URL + '/api/products?ws_key=' + process.env.VUE_APP_API_KEY + '&filter[active]=1&filter[online_only]=0&display=full&output_format=JSON&sort=id_DESC', {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            params: {
                'dataType': 'script'
            },
            // auth: {
            //     username: process.env.VUE_APP_API_KEY,
            //     password: ''
            // }
        });
    }

    getAllProductsWithLimit(offset, limit) {
        return axios.get(process.env.VUE_APP_API_BASE_URL + '/api/products?ws_key=' + process.env.VUE_APP_API_KEY + '&filter[id_category_default]=[40|82]&filter[active]=1&filter[online_only]=0&display=full&output_format=JSON&sort=id_DESC', {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            params: {
                'limit': offset+ ',' + limit,
                'dataType': 'script'
            },
            // auth: {
            //     username: process.env.VUE_APP_API_KEY,
            //     password: ''
            // }
        });
    }

    getAllProductsByName(name) {
        return axios.get(process.env.VUE_APP_API_BASE_URL + '/api/products?ws_key=' + process.env.VUE_APP_API_KEY + '&filter[id_category_default]=[40|82]&filter[active]=1&filter[online_only]=0&display=full&output_format=JSON', {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            params: {
                'filter[name]': '%[' + name + ']%',
                'dataType': 'script'
            },
            // auth: {
            //     username: process.env.VUE_APP_API_KEY,
            //     password: ''
            // }
        });
    }

    getAllProductsByDescription(search) {
        return axios.get(process.env.VUE_APP_API_BASE_URL + '/api/products?ws_key=' + process.env.VUE_APP_API_KEY + '&filter[id_category_default]=[40|82]&filter[active]=1&filter[online_only]=0&display=full&output_format=JSON', {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            params: {
                'filter[description_short]': '%[' + search + ']%',
                'dataType': 'script'
            },
            // auth: {
            //     username: process.env.VUE_APP_API_KEY,
            //     password: ''
            // }
        });
    }

    getSingleProduct(id) {
        return axios.get(process.env.VUE_APP_API_BASE_URL + '/api/products?ws_key=' + process.env.VUE_APP_API_KEY + '&display=full&output_format=JSON', {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            params: {
                'filter[id]': id,
                'dataType': 'script'
            },
            // auth: {
            //     username: process.env.VUE_APP_API_KEY,
            //     password: ''
            // }
        });
    }

    getAllProductsByCategory(id) {
        return axios.get(process.env.VUE_APP_API_BASE_URL + '/api/products?ws_key=' + process.env.VUE_APP_API_KEY + '&filter[active]=1&filter[online_only]=0&display=full&output_format=JSON', {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            params: {
                'filter[id_category_default]': id,
                'dataType': 'script'
            }
        });
    }
    getAllProductsByCategoryNoLimit(id) {
        return axios.get(process.env.VUE_APP_API_BASE_URL + '/api/categories?ws_key=' + process.env.VUE_APP_API_KEY + '&filter[active]=1&display=full&output_format=JSON', {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            params: {
                'filter[id]': id,
                'dataType': 'script'
            }
        });
    }
}

export default new ProductsService();
