<template>
  <header id="l-header">
    <div class="l-constrained">
      <nav>
        <div class="logo">
          <img src="@/assets/img/aromeshop-logo.png" alt="Arôme Shop logo">
        </div>
      </nav>
    </div>
  </header>
  <div v-if="$route.name !== 'category'" class="navigation">
    <div class="l-constrained">
      <NavbarComponent />
    </div>
  </div>
</template>

<script>
import NavbarComponent from "@/components/NavbarComponent";

export default {
  name: "HeaderComponent",
  components: {
    NavbarComponent
  }
}
</script>

<style scoped lang="scss">

#l-header {
  border-top: 8px solid var(--c-primary);
  border-bottom: 1px solid #dedede;
  padding: 32px 0;
  background-image: url("@/assets/img/header-bg.jpg");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;

  nav {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;

    ul {
      display: flex;
      align-items: center;
      padding: 0;
      margin: 0;

      li a {
        text-decoration: none;
        color: #222527;
        padding: 8px 16px;
        margin: 0 8px;
        font-weight: bold;
        text-transform: uppercase;

        &:hover {
          color: #ff9a52;
        }
      }
    }
  }
}
.navigation {
  padding: 32px 0;
  background-color: #222527;
}
</style>