<template>
<div class="search-container">
    <div class="l-constrained">
      <SearchBar :input="onInput"/>
    </div>
  </div>
  <div class="page">
    <div class="l-constrained">
    
    <a @click="$router.push('/')" class="router-link back btn">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
                d="M20.3284 11.0001V13.0001L7.50011 13.0001L10.7426 16.2426L9.32842 17.6568L3.67157 12L9.32842 6.34314L10.7426 7.75735L7.49988 11.0001L20.3284 11.0001Z"
                fill="currentColor"
            />
          </svg>
          Retour à l'accueil
        </a>
      <div class="flex-text">
        <h1 class="page-title" v-if="$route.params.id === '41'">Flacon de 10ml</h1>
        <h1 class="page-title" v-else-if="$route.params.id === '92'">Flacon de 50ml et +</h1>
        <h1 class="page-title" v-else-if="$route.params.id === '81'">DIY (Le fait maison)</h1>
      </div>
      <p v-if="error !== ''" class="error">{{ error }}</p>
      <ProductsComponent :products="products" />
      <p v-if="message !== ''">{{ message }}</p>
    </div>
    <ScrollToTopComponent />
  </div>
</template>

<script>
import ProductsComponent from "@/components/ProductsComponent";
import ScrollToTopComponent from "@/components/ScrollToTopComponent";
import SearchBar from "@/components/SearchBar";

export default {
  name: "CategoryView",
  components: {
    ProductsComponent,
    ScrollToTopComponent,
    SearchBar
  },
  data() {
    return {
      products: [],
      message: "",
      error: "",
    };
  },
  beforeCreate() {
    if (Number.isNaN(Number.parseInt(this.$route.params.id, 10)) || localStorage.getItem("products") === null) {
      this.$router.push("/");
    }
  },
  mounted() {
    this.getAll();
  },
  methods: {
    async getAll() {
      const LOCAL_PRODUCTS = JSON.parse(localStorage.getItem("products"));
      this.products = [];
      this.isLoading = true;

      switch (this.$route.params.id) {
        case '41': // 10 Ml
          LOCAL_PRODUCTS.forEach((product) => {
            const CATEGORIES = product.associations.categories;

            if (this.products.includes(product) === false) {
              CATEGORIES.forEach((category) => {
                if (category.id === '41') {
                  this.products.push(product);
                }
              });
            }
          });
          break;
        case '92': // 50 Ml +
          LOCAL_PRODUCTS.forEach((product) => {
            const CATEGORIES = product.associations.categories;

            if (this.products.includes(product) === false) {
              CATEGORIES.forEach((category) => {
                if (category.id === '92') {
                  this.products.push(product);
                }
              });
            }
          });
          break;

        case '81': // DIY
          LOCAL_PRODUCTS.forEach((product) => {
            const CATEGORIES = product.associations.categories;

            if (this.products.includes(product) === false) {
              CATEGORIES.forEach((category) => {
                if (category.id === '81') {
                  this.products.push(product);
                }
              });
            }
          });
          break;
      }
      this.isLoading = false;
    },
    // If the user search something, we load the products with the search value
    async onInput(e) {
      const LOCAL_PRODUCTS = JSON.parse(localStorage.getItem('products'));
      let input = e.target;
      let searchContainer = input.parentElement;
      this.searchValue = input.value;
      this.error = "";
      this.products = [];
      this.isLoading = true;

      // If search value is superior to 3, get products
      if(this.searchValue.length >= 3) {
        this.products = [];
        input.setAttribute('disabled', 'true');
        searchContainer.classList.add('disabled');

        //search with value
        LOCAL_PRODUCTS.forEach(product => {
          const CATEGORIES = product.associations.categories;

          CATEGORIES.forEach(category => {
            if (category.id === this.$route.params.id) {
              if(product.name.toLowerCase().includes(this.searchValue.toLowerCase()) || product.description_short.toLowerCase().includes(this.searchValue.toLowerCase())) {
                this.products.push(product);
              }
            }
          });
        });

        this.isLoading = false;
        input.removeAttribute('disabled');
        searchContainer.classList.remove('disabled');

        if(this.products.length === 0) {
          this.error = "Aucun résultat pour votre recherche";
        }

        input.focus();

      } else {
        this.offset = 0;

        for(let i = this.offset; i < this.limit; i++) {
          this.products.push(LOCAL_PRODUCTS[i]);
        }
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.search-container {
  position: sticky;
  top: 0;
  z-index: 15;
  padding: 32px 0;
  background-color: #222527;
}
.error {
  text-align: center;
  font-size: 16px;
  padding: 8px 16px;
  background: #ffeaea;
  border-radius: 4px;
}
.back {
  margin-bottom: 32px;
  width: max-content;
}
@media (max-width: 768px) {
  .back {
    margin: 0 auto 32px;
  }
}
</style>
